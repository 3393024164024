import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/Sitemap.css'; // Import the stylesheet
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const Sitemap = () => {
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [blogs, setBlogs] = useState([]);
    
    // Fetch the categories, tags, and blogs when the component mounts
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-categories`);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchTags = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-tags`);
                setTags(response.data);
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        };

        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-blogs`);
                const blogTitles = response.data.map(blog => blog.title); // Extract blog titles
                setBlogs(blogTitles);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchCategories();
        fetchTags();
        fetchBlogs();
    }, []);

    const staticLinks = [
        { url: '/', label: 'Home' },
        { url: '/about', label: 'About' },
        { url: '/services', label: 'Services' },
        { url: '/blog', label: 'Blog' },
        { url: '/privacy-policy', label: 'Privacy Policy' },  // Added
        { url: '/terms-of-service', label: 'Terms of Service' }  // Added
    ];
    

    return (
        <div className="html-sitemap-wrapper">
            <Helmet>
                <title>Sitemap - WattsWebWorks</title>
                <meta name="description" content="Explore the complete sitemap of WattsWebWorks, including links to all pages, blog categories, tags, and posts." />
                <meta property="og:title" content="Sitemap - WattsWebWorks" />
                <meta property="og:description" content="Explore the complete sitemap of WattsWebWorks, including links to all pages, blog categories, tags, and posts." />
                <meta property="og:url" content="https://www.wattswebworks.com/sitemap" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Sitemap - WattsWebWorks" />
                <meta name="twitter:description" content="Explore the complete sitemap of WattsWebWorks, including links to all pages, blog categories, tags, and posts." />
                <link rel="canonical" href="https://www.wattswebworks.com/sitemap" />
                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebSite",
                    "name": "WattsWebWorks Sitemap",
                    "url": "https://www.wattswebworks.com/sitemap",
                    "description": "Explore the complete sitemap of WattsWebWorks, including links to all pages, blog categories, tags, and posts.",
                    "publisher": {
                        "@type": "Organization",
                        "name": "WattsWebWorks",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://www.wattswebworks.com/logo512.png"
                        }
                    }
                })}
                </script>
            </Helmet>

            <h1 className="sitemap-title">Sitemap</h1>
            <div className="sitemap-container">
                <div className="sitemap-section">
                    <h2 className="sitemap-heading"><i className="fa-regular fa-bookmark"></i>&nbsp;Pages</h2>
                    <ul className="sitemap-list">
                        {staticLinks.map(link => (
                            <li key={link.url} className="sitemap-item">
                                <Link to={link.url} className="sitemap-link">{link.label}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="sitemap-section">
                    <h2 className="sitemap-heading"><i className="fa-solid fa-list"></i>&nbsp;Blog Categories</h2>
                    <ul className="sitemap-list">
                        {categories.map(category => (
                            <li key={category} className="sitemap-item">
                                <Link to={`/blog/category/${encodeURIComponent(category)}`} className="sitemap-link">
                                    {category}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="sitemap-section">
                    <h2 className="sitemap-heading"><i className="fa-solid fa-hashtag"></i>&nbsp;Blog Tags</h2>
                    <ul className="sitemap-list">
                        {tags.map(tag => (
                            <li key={tag} className="sitemap-item">
                                <Link to={`/blog/tag/${encodeURIComponent(tag)}`} className="sitemap-link">
                                    {tag}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="sitemap-section">
                    <h2 className="sitemap-heading"><i className="fa-regular fa-comment-dots"></i>&nbsp;Blog Posts</h2>
                    <ul className="sitemap-list">
                        {blogs.map(blog => (
                            <li key={blog} className="sitemap-item">
                                <Link to={`/blog/${encodeURIComponent(blog)}`} className="sitemap-link">
                                    {blog}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sitemap;
