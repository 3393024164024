import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import './styles/PrivacyPolicy.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import countryRoadsImage from '../assets/images/countryRoads.jpg';

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0); // Ensure the page starts at the top when loaded
    }, []);

    return (
        <>
            <Helmet>
                <title>Privacy Policy - WattsWebWorks</title>
                <meta name="description" content="Read the Privacy Policy for WattsWebWorks, outlining how we collect, use, and protect your information." />
                <meta property="og:title" content="Privacy Policy - WattsWebWorks" />
                <meta property="og:description" content="Read the Privacy Policy for WattsWebWorks, outlining how we collect, use, and protect your information." />
                <meta property="og:url" content="https://www.wattswebworks.com/privacy-policy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Privacy Policy - WattsWebWorks" />
                <meta name="twitter:description" content="Read the Privacy Policy for WattsWebWorks, outlining how we collect, use, and protect your information." />
                <link rel="canonical" href="https://www.wattswebworks.com/privacy-policy" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "Privacy Policy - WattsWebWorks",
                        "url": "https://www.wattswebworks.com/privacy-policy",
                        "description": "Read the Privacy Policy for WattsWebWorks, outlining how we collect, use, and protect your information.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "WattsWebWorks",
                            "url": "https://www.wattswebworks.com",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://www.wattswebworks.com/logo512.png"
                            }
                        },
                        "mainEntity": {
                            "@type": "FAQPage",
                            "name": "Privacy Policy",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What personal information does WattsWebWorks collect?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "We collect contact information (such as name, email, and phone number) when users submit inquiries through our website. We also use cookies and analytics tools to improve website functionality."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How does WattsWebWorks protect user data?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "We implement encryption at every step, including HTTPS for secure browsing and secure storage for sensitive data."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "Does WattsWebWorks share personal data with third parties?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "No, we do not sell or share personal data with third parties for marketing purposes. SMS consent and user data are never shared."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How can I opt out of SMS communications?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "You can opt out of SMS messages at any time by replying STOP or by contacting us directly at contact@wattswebworks.com."
                                    }
                                }
                            ]
                        }
                    })}
                </script>
            </Helmet>

            <div
                className="page-title"
                aria-label="Privacy Policy Page Title"
                style={{ backgroundImage: `url(${countryRoadsImage})` }}
            >
                <h1 className="title-text">Privacy Policy</h1>
            </div>

            <div className="privacy-policy-page">
                <section className="privacy-section">
                    <h2>Privacy Policy</h2>
                    <p><strong>Last Updated: </strong>9/4/2024</p>

                    <h3>1. Introduction</h3>
                    <p>WattsWebWorks ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and secure your information when you use our website or services.</p>

                    <h3>2. Information We Collect</h3>
                    <p>We may collect and process the following types of personal and non-personal data:</p>
                    <ul>
                        <li><strong>Contact Information:</strong> When you submit inquiries via our contact form, we may collect your name, email, phone number, and any additional information you provide.</li>
                        <li><strong>Project Data:</strong> If you engage our services, we may collect website-related content and technical details necessary for development.</li>
                        <li><strong>Cookies & Analytics:</strong> We use cookies and third-party tools such as Google Analytics to track user behavior, improve website functionality, and enhance security.</li>
                    </ul>

                    <h3>3. How We Use Your Information</h3>
                    <p>We use collected information for the following purposes:</p>
                    <ul>
                        <li>To respond to inquiries and provide customer support.</li>
                        <li>To develop, design, and maintain websites for clients.</li>
                        <li>To analyze website traffic and improve user experience.</li>
                        <li>To send business-related SMS messages, only when consent has been explicitly given.</li>
                    </ul>

                    <h3>4. Data Security & Encryption</h3>
                    <p>We implement strict security measures to protect your data:</p>
                    <ul>
                        <li><strong>End-to-End Encryption:</strong> Sensitive information provided via forms or communication is encrypted both in transit and at rest.</li>
                        <li><strong>Secure Storage:</strong> We store all collected data on secure, access-controlled servers configured and hardened for security.</li>
                        <li><strong>HTTPS & SSL:</strong> Our website uses SSL encryption to ensure secure communication between your browser and our servers.</li>
                    </ul>
                    <p>Despite our best efforts, no method of data transmission over the internet is 100% secure. If you suspect unauthorized access to your data, please contact us immediately.</p>

                    <h3>5. SMS Messaging & Consumer Data Protection</h3>
                    <p>We only send SMS messages to clients who have:</p>
                    <ul>
                        <li>Opted in through our website contact form.</li>
                        <li>Initiated communication via SMS.</li>
                        <li>Provided verbal or written consent during business interactions.</li>
                    </ul>
                    <p><strong>No mobile information will be shared with third parties or affiliates for marketing/promotional purposes.</strong> All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.</p>

                    <h3>6. Third-Party Services</h3>
                    <p>We use trusted third-party tools to enhance security, analytics, and website functionality, including:</p>
                    <ul>
                        <li><strong>Google Analytics:</strong> Tracks website performance and user behavior (opt-out available via Google settings).</li>
                        <li><strong>reCAPTCHA:</strong> Protects against spam and bot activity.</li>
                        <li><strong>UserWay:</strong> Improves website accessibility for users with disabilities.</li>
                    </ul>
                    <p>These services may collect usage data, but they do not have access to personal information you submit to us.</p>

                    <h3>7. Data Retention & User Rights</h3>
                    <p>We retain collected data only for as long as necessary to fulfill business and legal obligations. Users have the right to:</p>
                    <ul>
                        <li>Request access to their personal data.</li>
                        <li>Request corrections or updates to their data.</li>
                        <li>Request deletion of their data (subject to legal and contractual obligations).</li>
                    </ul>
                    <p>To make a data request, contact us at <a href="mailto:contact@wattswebworks.com">contact@wattswebworks.com</a>.</p>

                    <h3>8. Opt-Out & Data Removal</h3>
                    <p>Clients may opt out of SMS messaging by:</p>
                    <ul>
                        <li>Replying "STOP" to an SMS message.</li>
                        <li>Emailing us at <a href="mailto:contact@wattswebworks.com">contact@wattswebworks.com</a> with an opt-out request.</li>
                    </ul>

                    <h3>9. Changes to This Privacy Policy</h3>
                    <p>We reserve the right to update this Privacy Policy at any time. Changes will be reflected on this page, and continued use of our website constitutes acceptance of any modifications.</p>

                    <h3>10. Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy or how we handle your data, please contact us at:</p>
                    <p><strong>WattsWebWorks</strong></p>
                    <p>Email: <a href="mailto:contact@wattswebworks.com">contact@wattswebworks.com</a></p>
                    <p>Contact: <HashLink to="/about#contact">Contact</HashLink></p>
                </section>
            </div>
        </>
    );
}
