import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import './styles/PrivacyPolicy.css'; // Using the same styling for consistency
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import countryRoadsImage from '../assets/images/countryRoads.jpg';

export default function TermsOfService() {
    useEffect(() => {
        window.scrollTo(0, 0); // Ensure the page starts at the top when loaded
    }, []);

    return (
        <>
            <Helmet>
                <title>Terms of Service - WattsWebWorks</title>
                <meta name="description" content="Read the Terms of Service for WattsWebWorks, outlining the rules and responsibilities for using our website and services." />
                <meta property="og:title" content="Terms of Service - WattsWebWorks" />
                <meta property="og:description" content="Read the Terms of Service for WattsWebWorks, outlining the rules and responsibilities for using our website and services." />
                <meta property="og:url" content="https://www.wattswebworks.com/terms-of-service" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Terms of Service - WattsWebWorks" />
                <meta name="twitter:description" content="Read the Terms of Service for WattsWebWorks, outlining the rules and responsibilities for using our website and services." />
                <link rel="canonical" href="https://www.wattswebworks.com/terms-of-service" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "Terms of Service - WattsWebWorks",
                        "url": "https://www.wattswebworks.com/terms-of-service",
                        "description": "Read the Terms of Service for WattsWebWorks, outlining the rules and responsibilities for using our website and services.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "WattsWebWorks",
                            "url": "https://www.wattswebworks.com",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://www.wattswebworks.com/logo512.png"
                            }
                        },
                        "mainEntity": {
                            "@type": "FAQPage",
                            "name": "Terms of Service",
                            "mainEntity": [
                                {
                                    "@type": "Question",
                                    "name": "What services does WattsWebWorks provide?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "WattsWebWorks provides web development, design, and digital solutions for clients. Services are customized based on business needs."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "What are my responsibilities as a client?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Clients must provide accurate information, ensure they have the rights to any content they provide, and comply with agreed-upon project timelines."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "How does WattsWebWorks handle payments and refunds?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Payments are required as outlined in project agreements. Refunds are at WattsWebWorks' discretion based on project completion and agreement terms."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "Does WattsWebWorks use third-party services?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Yes, WattsWebWorks may use Google Analytics, reCAPTCHA, and other services to improve website security and performance. More details are available in the Privacy Policy."
                                    }
                                },
                                {
                                    "@type": "Question",
                                    "name": "Can WattsWebWorks terminate services?",
                                    "acceptedAnswer": {
                                        "@type": "Answer",
                                        "text": "Yes, we reserve the right to terminate services if a client violates our terms, engages in unlawful activities, or breaches a project agreement."
                                    }
                                }
                            ]
                        }
                    })}
                </script>
            </Helmet>

            <div
                className="page-title"
                aria-label="Terms of Service Page Title"
                style={{ backgroundImage: `url(${countryRoadsImage})` }}
            >
                <h1 className="title-text">Terms of Service</h1>
            </div>

            <div className="privacy-policy-page">
                <section className="privacy-section">
                    <h2>Terms of Service</h2>
                    <p><strong>Last Updated: </strong>9/4/2024</p>

                    <h3>1. Acceptance of Terms</h3>
                    <p>By accessing or using our website and services, you agree to comply with and be bound by these Terms of Service.</p>

                    <h3>2. Services Provided</h3>
                    <p>WattsWebWorks provides web development, design, and related digital services. By using our services, you acknowledge that project timelines, deliverables, and costs may be subject to written or verbal agreements between you and WattsWebWorks.</p>

                    <h3>3. User Responsibilities</h3>
                    <ul>
                        <li>You agree to provide accurate and complete information when engaging our services.</li>
                        <li>You shall not use our website or services for unlawful, fraudulent, or malicious purposes.</li>
                        <li>Any content you provide for development (e.g., images, text, branding) must be properly licensed or owned by you.</li>
                    </ul>

                    <h3>4. Intellectual Property</h3>
                    <p>All original content, designs, and code created by WattsWebWorks remain the property of WattsWebWorks unless otherwise specified in a written agreement. Clients are granted a license to use the work for its intended purpose.</p>
                    <p>Unauthorized reproduction, resale, or distribution of any proprietary materials is strictly prohibited.</p>

                    <h3>5. Payments & Refunds</h3>
                    <ul>
                        <li>Payments for services must be made as outlined in invoices or agreements.</li>
                        <li>Refunds may be provided at WattsWebWorks' discretion, based on project progress and agreement terms.</li>
                    </ul>

                    <h3>6. Limitation of Liability</h3>
                    <p>WattsWebWorks is not liable for indirect, incidental, or consequential damages resulting from the use of our website or services. We do not guarantee uninterrupted or error-free operation of our website or third-party integrations.</p>

                    <h3>7. Third-Party Services</h3>
                    <p>We may use third-party services such as Google Analytics, UserWay, and reCAPTCHA. These services may collect and process data as described in our <Link to="/privacy-policy">Privacy Policy</Link>.</p>

                    <h3>8. Data Security</h3>
                    <p>We take reasonable steps to protect your personal data using encryption, secure storage, and HTTPS protocols. However, no online service is entirely secure, and you acknowledge that any data transmission carries inherent risks.</p>

                    <h3>9. Termination of Services</h3>
                    <p>WattsWebWorks reserves the right to terminate or suspend access to our services for violations of these terms or other applicable laws.</p>

                    <h3>10. Changes to These Terms</h3>
                    <p>We reserve the right to update these Terms of Service at any time. Continued use of our website or services after updates constitutes acceptance of the revised terms.</p>

                    <h3>11. Contact Information</h3>
                    <p>If you have any questions about these Terms of Service, please contact us at:</p>
                    <p><strong>WattsWebWorks</strong></p>
                    <p>Email: <a href="mailto:contact@wattswebworks.com">contact@wattswebworks.com</a></p>
                    <p>Contact: <HashLink to="/about#contact">Contact</HashLink></p>
                </section>
            </div>
        </>
    );
}
